
import {
  defineComponent, computed, reactive, onBeforeMount,
} from 'vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import Icone from '../Icone.vue';
import BuscaAvancada from './BuscaAvancada.vue';
import OrdenacaoBusca from './OrdenacaoBusca.vue';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'BuscaGeral',
  props: {
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    ordenacao: {
      type: Array as () => IOrdenacao[],
      required: true,
    },
    buscaAvancada: {
      type: Object as () => IBuscaAvancada,
      required: true,
    },
    buscaRapida: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Busca rápida: digite uma palavra ou um valor para acharmos seu registro...',
    },
  },
  emits: ['update:ordenacao', 'update:buscaAvancada', 'update:buscaRapida', 'buscarDados'],
  components: {
    Icone,
    BuscaAvancada,
    OrdenacaoBusca,
  },
  setup(props, { emit }) {
    let debounce = 0;
    const state = reactive({
      exibirBuscaAvancada: false,
      exibirOrdenacao: false,
      mobile: false,
    });

    const computedOrdenacao = computed({
      get: () => props.ordenacao,
      set: (val) => {
        emit('update:ordenacao', val);
      },
    });

    const computedBuscaAvancada = computed({
      get: () => props.buscaAvancada,
      set: (val) => {
        emit('update:buscaAvancada', val);
      },
    });

    const comptedBuscaRapida = computed({
      get: () => props.buscaRapida,
      set: (val) => {
        emit('update:buscaRapida', val);
      },
    });

    function fecharBuscaAvancada() {
      state.exibirBuscaAvancada = false;
    }

    function fecharOrdenacao() {
      state.exibirOrdenacao = false;
    }

    function buscarDados() {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        emit('buscarDados');
      }, 600);
    }

    function limparBuscaRapida() {
      comptedBuscaRapida.value = '';
      emit('buscarDados');
    }

    onBeforeMount(async () => {
      state.mobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    function apresentarOrdenacao(): boolean {
      if (UtilitarioGeral.objetoValido(computedBuscaAvancada.value)) {
        return UtilitarioGeral.validaLista(computedBuscaAvancada.value.ordenacao);
      }
      return false;
    }

    function apresentarFiltrosAdicionados(): string {
      let apresentar = false;

      if (UtilitarioGeral.objetoValido(computedBuscaAvancada.value)) {
        apresentar = UtilitarioGeral.validaLista(computedBuscaAvancada.value.filtrosAdicionados);
      }

      if (apresentar) {
        if (computedBuscaAvancada.value.filtrosAdicionados.length > 9) {
          return '+9';
        }
        return computedBuscaAvancada.value.filtrosAdicionados.length.toString();
      }
      return '';
    }

    function apresentacaoBuscaAvancada():string {
      const filtrosAdicionados = apresentarFiltrosAdicionados();
      if (filtrosAdicionados !== '') {
        if (filtrosAdicionados === '1') {
          return 'A Busca avançada possui um filtro adicionado.';
        }
        return `A Busca avançada possui: ${filtrosAdicionados} Filtros adicionados.`;
      }
      return 'Busca avançada';
    }

    return {
      props,
      state,
      computedBuscaAvancada,
      comptedBuscaRapida,
      computedOrdenacao,
      apresentarOrdenacao,
      fecharBuscaAvancada,
      fecharOrdenacao,
      buscarDados,
      apresentacaoBuscaAvancada,
      apresentarFiltrosAdicionados,
      limparBuscaRapida,
    };
  },
});
