
import {
  defineComponent, reactive, watch,
} from 'vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '../Icone.vue';

export default defineComponent({
  components: {
    Icone,
  },
  props: {
    arquivos: {
      type: Array as () => IArquivoPdf[],
      default: () => [],
    },
    tituloVariosArquivos: {
      type: String,
      default: 'Impressões Disponíveis',
    },
  },
  emits: ['fechado'],
  setup(props, { emit }) {
    let debouncePreview = 0;
    let adobeDCView: any = null;

    const state = reactive({
      visivel: false,
      indexArquivoEmVisualizacao: 0,
    });

    function preVisualizarPdf(index:number) {
      state.indexArquivoEmVisualizacao = index;
      const arquivoPdf = props.arquivos[index];
      if (!UtilitarioGeral.valorValido(arquivoPdf.nome)) {
        arquivoPdf.nome = UtilitarioGeral.obterNomeArquivo(arquivoPdf.link);
      }

      const clienteId = window.location.href.includes('localhost') ? '3f27ff7a59b944e4aaedf79844c131c1' : '6d9a186bafe14dfc955a68a8f658ebb6';
      adobeDCView = new window.AdobeDC.View({ clientId: clienteId, divId: 'adobe-dc-view', locale: 'pt-BR' });
      adobeDCView.previewFile({
        content: { location: { url: arquivoPdf.link } },
        metaData: { fileName: arquivoPdf.nome },
      }, {
        showAnnotationTools: false,
        showDownloadPDF: true,
        showPrintPDF: true,
        embedMode: 'LIGHT_BOX',
        defaultViewMode: 'FIT_WIDTH',
      });

      const eventOptions = {
        // Pass the events to receive.
        // If no event is passed in listenOn, then all file preview events will be received.
        listenOn: [window.AdobeDC.View.Enum.Events.PDF_VIEWER_CLOSE],
        enableFilePreviewEvents: true,
      };

      adobeDCView.registerCallback(
        window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
        () => {
          state.visivel = false;
        }, eventOptions,
      );
    }

    watch(async () => state.visivel, async () => {
      if (state.visivel === false) {
        emit('fechado');
      }
    });

    watch(() => props.arquivos, (newValue) => {
      if (newValue.length > 0) {
        state.visivel = true;
        debouncePreview = setTimeout(() => {
          preVisualizarPdf(props.arquivos.length - 1); // Previsualiza sempre o último arquivo
        }, 600);
      }
    }, {
      immediate: true,
    });

    return {
      state,
      debouncePreview,
      props,
      preVisualizarPdf,
      UtilitarioGeral,
    };
  },
});
