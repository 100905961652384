
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import { IDTOUsuarioRegistroAtividade } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioRegistroAtividade';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'HistoricoRecursoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    identificadorRecurso: {
      type: String,
      required: true,
    },
    empresasSelecionadas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
  },
  setup(props, { emit }) {
    const tela = reactive({
      registros: [] as IDTOUsuarioRegistroAtividade[],
      possuiHistorico: true,
      carregandoDados: false,
      computedVisivel: computed({
        get: () => props.visivel,
        set: (val: boolean) => {
          emit('update:visivel', val);
        },
      }),
    });

    watch(async () => tela.computedVisivel, async (visivel) => {
      tela.carregandoDados = true;

      tela.registros = [];
      if (await visivel) {
        const listaRegistroAtividades = await new ServicoSistema().obterRegistroAtividadeRecurso(props.identificadorRecurso, props.empresasSelecionadas);
        if (UtilitarioGeral.validaLista(listaRegistroAtividades)) {
          tela.registros = listaRegistroAtividades;
          tela.possuiHistorico = true;
        } else {
          tela.possuiHistorico = false;
        }
      }
      tela.carregandoDados = false;
    });

    return {
      tela,
    };
  },
});
