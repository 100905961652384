import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75b5e84d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_2 = { class: "ant-input-group-addon" }
const _hoisted_3 = {
  role: "img",
  "aria-label": "pesquisar",
  type: "pesquisar",
  class: "anticon"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "ant-input-group-addon"
}
const _hoisted_6 = { class: "ant-input-group-addon" }
const _hoisted_7 = {
  key: 0,
  class: "ss-busca-geral-quantidade-filtros"
}
const _hoisted_8 = {
  key: 1,
  class: "ant-input-group-addon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_busca_avancada = _resolveComponent("busca-avancada")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_ordenacao_busca = _resolveComponent("ordenacao-busca")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_icone, {
            style: {"font-size":"18px"},
            nome: "pesquisar"
          })
        ])
      ]),
      _withDirectives(_createElementVNode("input", {
        placeholder: _ctx.props.placeholder,
        type: "text",
        class: "ant-input",
        style: {"text-align":"left !important"},
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comptedBuscaRapida) = $event)),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.buscarDados()))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.comptedBuscaRapida]
      ]),
      (_ctx.comptedBuscaRapida !== '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createVNode(_component_a_tooltip, {
              title: "Limpar busca rápida",
              placement: "left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.limparBuscaRapida()))
                }, [
                  _createVNode(_component_icone, {
                    style: {"font-size":"18px","margin-right":"13px"},
                    nome: "clear"
                  })
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_a_popover, {
          style: {"width":"100%"},
          open: _ctx.state.exibirBuscaAvancada,
          "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.exibirBuscaAvancada) = $event)),
          title: "Busca avançada",
          placement: "topLeft",
          trigger: "click"
        }, {
          content: _withCtx(() => [
            _createVNode(_component_busca_avancada, {
              buscaAvancada: _ctx.computedBuscaAvancada,
              "onUpdate:buscaAvancada": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedBuscaAvancada) = $event)),
              empresas: _ctx.props.empresas,
              onAplicarFiltros: _ctx.buscarDados
            }, null, 8, ["buscaAvancada", "empresas", "onAplicarFiltros"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_tooltip, {
              title: _ctx.apresentacaoBuscaAvancada(),
              placement: "left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", null, [
                  _createVNode(_component_icone, {
                    style: {"font-size":"18px","margin-right":"13px"},
                    nome: "filtros"
                  })
                ]),
                (_ctx.apresentarFiltrosAdicionados())
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.apresentarFiltrosAdicionados()), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["open"])
      ]),
      (_ctx.apresentarOrdenacao())
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
            _createVNode(_component_a_popover, {
              style: {"width":"100%"},
              open: _ctx.state.exibirOrdenacao,
              "onUpdate:open": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.exibirOrdenacao) = $event)),
              title: "Ordenação",
              placement: "topLeft",
              trigger: "click"
            }, {
              content: _withCtx(() => [
                _createVNode(_component_ordenacao_busca, {
                  propriedadesOrdenacao: _ctx.props.buscaAvancada.ordenacao,
                  ordenacao: _ctx.computedOrdenacao,
                  "onUpdate:ordenacao": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedOrdenacao) = $event)),
                  onOrdenacaoAlterada: _ctx.buscarDados
                }, null, 8, ["propriedadesOrdenacao", "ordenacao", "onOrdenacaoAlterada"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_tooltip, {
                  title: "Ordenação",
                  placement: "left"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", null, [
                      _createVNode(_component_icone, {
                        style: {"font-size":"18px"},
                        nome: "ordenacao"
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["open"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}