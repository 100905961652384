
import {
  defineComponent, onBeforeMount, computed, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '../Icone.vue';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { EOrdenacao } from '@/core/models/Enumeradores/EOrdenacao';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'OrdenacaoBusca',
  props: {
    propriedadesOrdenacao: {
      type: Array as () => IPropriedadeConsulta[],
      required: true,
    },
    ordenacao: {
      type: Array as () => IOrdenacao[],
      required: true,
    },
  },
  emits: ['update:ordenacao', 'ordenacaoAlterada'],
  components: {
    Icone,
    draggable,
  },
  setup(props, { emit }) {
    const state = reactive({
      propriedadesOrdenadas: [] as IPropriedadeConsulta[],
    });

    const computedOrdenacao = computed({
      get: () => props.ordenacao,
      set: (val) => {
        emit('update:ordenacao', val);
      },
    });

    function preenchePropriedadesOrdenacao() {
      state.propriedadesOrdenadas = [];
      const propriedadesOrdenacao = UtilitarioGeral.instanciaObjetoLocal(props.propriedadesOrdenacao) as IPropriedadeConsulta[];
      if (UtilitarioGeral.validaLista(computedOrdenacao.value)) {
        computedOrdenacao.value.forEach((ordenacao) => {
          const indexPropriedade = propriedadesOrdenacao.findIndex((c) => c.identificador === ordenacao.identificador);
          if (indexPropriedade >= 0) {
            state.propriedadesOrdenadas.push(propriedadesOrdenacao[indexPropriedade]);
            propriedadesOrdenacao.splice(indexPropriedade, 1);
          }
        });
      }
      propriedadesOrdenacao.forEach((propriedadeOrdenacaoordenacao) => {
        state.propriedadesOrdenadas.push(propriedadeOrdenacaoordenacao);
      });
    }
    function alterarOrdem(identificador: string, ordem:string) {
      const index = computedOrdenacao.value.findIndex((c) => c.identificador === identificador);
      if (index >= 0) {
        if (computedOrdenacao.value[index].ordem === ordem) {
          computedOrdenacao.value.splice(index, 1);
        } else {
          computedOrdenacao.value[index].ordem = ordem;
        }
      } else {
        computedOrdenacao.value.push({ identificador, descricao: '', ordem } as IOrdenacao);
      }
      preenchePropriedadesOrdenacao();
      emit('ordenacaoAlterada');
    }

    function atualizarOrdenacao() {
      emit('ordenacaoAlterada');
    }

    function obtemOrdem(identificador:string):string {
      const index = computedOrdenacao.value.findIndex((c) => c.identificador === identificador);
      if (index >= 0) {
        return computedOrdenacao.value[index].ordem;
      }

      return '';
    }

    onBeforeMount(() => {
      preenchePropriedadesOrdenacao();
    });

    return {
      props,
      state,
      ETipoDado,
      EOrdenacao,
      computedOrdenacao,
      alterarOrdem,
      atualizarOrdenacao,
      obtemOrdem,
    };
  },
});
