
import { defineComponent, reactive } from 'vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import HistoricoRecursoModal from '@/core/components/Modal/HistoricoRecursoModal.vue';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'TituloPadrao',
  props: {
    tituloRecurso: {
      type: String,
      required: true,
    },
    identificadorRecurso: {
      type: String,
      required: true,
    },
    empresasSelecionadas: {
      type: Array as () => number[],
      required: true,
    },
    exibirAtalhosTela: {
      type: Boolean,
      default: false,
    },
    exibirHistoricoTela: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    HistoricoRecursoModal,
  },
  setup(props) {
    const {
      adicionarAtalho, removerAtalho,
    } = useTelaBase();

    const stateTituloPadrao = reactive({
      exibirHistorico: false,
    });

    function apresentarHistorico() {
      stateTituloPadrao.exibirHistorico = !stateTituloPadrao.exibirHistorico;
    }

    return {
      props,
      storeSistema,
      stateTituloPadrao,
      adicionarAtalho,
      removerAtalho,
      apresentarHistorico,
    };
  },
});
