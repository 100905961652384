export enum ETipoArquivo {
    PDF = 1,
    XML = 2,
    Word = 3,
    Excel = 4,
    CSV = 5,
    Texto = 6,
    JPG = 7,
    PNG = 8,
    SVG = 9,
}
